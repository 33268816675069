<template>
    <div v-loading.fullscreen.lock="loading">
        <cooperative-enterprise-search-bar-component
            add_auth="新增合作企业申请"
            :AllType="AllType"
            :AllAccountPeriodType="AllAccountPeriodType"
            :AllStaff="AllStaff"
            :AllEnterprise="AllEnterprise"
            :daoqi_num="daoqi_num"
            @search="get_cooperative_enterprise_index"
            @addTeam="addTeam"
            @out_excel="outExcel"
        ></cooperative-enterprise-search-bar-component>
        <common-table-component
            details_auth="合作企业申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_cooperative_enterprise"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_cooperative_enterprise_index"
        ></common-page-component>
        <cooperative-enterprise-edit-component
            :id="fid"
            add_auth="新增合作企业申请"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :cooperative_enterprise_details_data="cooperative_enterprise_details_data"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :AllType="AllType"
            :AllAccountPeriodType="AllAccountPeriodType"
            :AllBusinessType="AllBusinessType"
            :AllStaff="AllStaff"
            :AllBankCard="AllBankCard"
            :AllEnterprise="AllEnterprise"
            :AllProvideServices="AllProvideServices"
            :AllBusinessSection="AllBusinessSection"
            :AllClassify="AllClassify"
            print_auth="打印合作企业申请"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_cooperative_enterprise_index"
            @exitDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_cooperative_enterprise"
            @refresh_bank_card_list="refresh_bank_card_list"
        ></cooperative-enterprise-edit-component>
        <!-- 导出 -->
        <common-out-put-excel-component
            ref="out_excel"
            :TableName="out_excel_name"
            :HeaderArr="out_excel_heard"
            :BobyArr="out_excel_data"
        ></common-out-put-excel-component>
    </div>
</template>

<script>
import { cooperative_enterprise_index_request,cooperative_enterprise_details_request,out_excel_request} from '@/network/business/CooperativeEnterprise.js'
import { staff_list_request,bank_card_list_request,client_list_request } from '@/network/list.js'

import CooperativeEnterpriseSearchBarComponent from '@/components/business/CooperativeEnterprise/CooperativeEnterpriseSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CooperativeEnterpriseEditComponent from '@/components/business/CooperativeEnterprise/CooperativeEnterpriseEditComponent'

import CommonOutPutExcelComponent from '@/components/common/CommonOutPutExcelComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                status: '',
                contract_state: '',
                enterprise_name: '',
                type: '',
                account_period_type: '',
                supervisor: '',
                daoqi: false,
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '单号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'status',
                    label: '合作状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'danger',
                                filter_data: '禁用'
                            }
                        } else if(d===1) {
                            return {
                                type: 'warning',
                                filter_data: '临时'
                            }
                        } else if(d===2) {
                            return {
                                type: 'primary',
                                filter_data: '长期'
                            }
                        }
                    }
                },
                {
                    prop: 'contract_state',
                    label: '合同状态',
                    minWidth: '100px',
                    html: (row) => {
                        if(row['contract_state']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">不签</span></div>`
                        } else if(row['contract_state']===1) {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">待签</span></div>`
                        } else if(row['contract_state']===2) {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">已签</span></div>`
                        } else if(row['contract_state']===3) {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">过期</span></div>`
                        }
                    }
                },
                {
                    prop: 'contract_date_name',
                    label: '预计签订日',
                    minWidth: '100px',
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'with_zhwl_enterprise',
                    label: '我方企业',
                    minWidth: '260px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'enterprise_name',
                    label: '对方企业',
                    minWidth: '260px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'business_type_name',
                    label: '业务类型',
                    minWidth: '260px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'account_period_type_name',
                    label: '账期类型',
                    minWidth: '120px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'staff_name',
                    label: '申请人',
                    minWidth: '100px',
                },
                {
                    prop: 'create_time',
                    label: '申请日期',
                    minWidth: '100px',
                }
            ],
            cooperative_enterprise_details_data: {},
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false,
            AllType: ['客户','供应商','我方企业'],
            AllAccountPeriodType: ['付款买单','票结','月结','季结','半年结','年结','其它'],
            AllBusinessType: ['海运进口','海运出口','空运进口','空运出口','铁路进口','铁路出口','公路运输','多式联运','仓储装卸','理货报关','服务'],
            AllStaff: [],
            AllBankCard: [],
            AllEnterprise: [],
            AllProvideServices:['装卸','报关','报检','海运','空运','江运','铁路','仓储','保险','包装','地面','拖车','国外','理货'],
            AllBusinessSection: ['钢材板块','汽车板块','传统货代'],
            AllClassify: ['绑扎材料','保险公司','报关公司','报检公司','仓储公司','舱单充值','船公司','地面代理','订舱代理','海外代理','空运代理','货代公司','快递公司','理货公司','拼箱代理','铁路代理','铁路公司','拖车公司','装箱公司','码头','修箱'],
            out_excel_heard: [],
            out_excel_data: [],
            daoqi_total: 0
        }
    },
    computed:{
        daoqi_num() {
            return this.daoqi_total === 0 ? '' : this.daoqi_total
        },
        out_excel_name() {
            const n = new Date();
            const year = n.getFullYear();
            const month = (((n.getMonth()) + 1)+'').length === 1 ? ('0' + ((n.getMonth()) + 1)) : ((n.getMonth()) + 1);
            const date = (n.getDate()+'').length === 1 ? ('0' + n.getDate()) : n.getDate();
            const hour = (n.getHours()+'').length === 1 ? ('0' + n.getHours()) : n.getHours();
            const minute = (n.getMinutes()+'').length === 1 ? ('0' + n.getMinutes()) : n.getMinutes();
            const second = (n.getSeconds()+'').length === 1 ? ('0' + n.getSeconds()) : n.getSeconds();

            return '合作企业' + year +  month + date +  hour +  minute + second
        }
    },
    methods:{
        get_cooperative_enterprise_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.status = param.status ?? this.cond.status
            this.cond.contract_state = param.contract_state ?? this.cond.contract_state
            this.cond.is_cooperation = param.is_cooperation ?? this.cond.is_cooperation
            this.cond.enterprise_name = param.enterprise_name ?? this.cond.enterprise_name
            this.cond.type = param.type ?? this.cond.type
            this.cond.account_period_type = param.account_period_type ?? this.cond.account_period_type
            this.cond.supervisor = param.supervisor ?? this.cond.supervisor
            this.cond.daoqi = param.daoqi ?? this.cond.daoqi
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            cooperative_enterprise_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                        this.daoqi_total = s.result.count_daoqi
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_cooperative_enterprise(id) {
            this.fid = id
            this.fdialogFormVisible = true
            cooperative_enterprise_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.cooperative_enterprise_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        frefresh() {
            this.details_cooperative_enterprise(this.fid)
            this.get_cooperative_enterprise_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合作企业申请')
                    this.edit_auth = this.$_has('修改合作企业申请')
                    this.del_auth = this.$_has('删除合作企业申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回合作企业申请')
                    this.add_file_auth = this.$_has('补充合作企业申请附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批合作企业申请')
                }
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充合作企业申请附件')
                }
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合作企业申请')
                    this.edit_auth = this.$_has('修改合作企业申请')
                    this.del_auth = this.$_has('删除合作企业申请')
                }
                this.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
            }
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_all_bank_card() {
            bank_card_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllBankCard = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            client_list_request({type: 2})
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        refresh_bank_card_list() {
            this.get_all_bank_card()
        },
        outExcel() {
            this.out_excel_heard = [
                {label: '申请日期',prop: 'create_time'},
                {label: '审批时间',prop: 'examine_time'},
                {label: '单号',prop: 'name'},
                {label: '审批状态',prop: 'state_name'},
                {label: '合作状态',prop: 'status_name'},
                {label: '合同状态',prop: 'contract_state_name'},
                {label: '预计签订日',prop: 'contract_date_name'},
                {label: '我方企业',prop: 'with_zhwl_enterprise'},
                {label: '对方企业',prop: 'enterprise_name'},
                {label: '合作类型',prop: 'type_name'},
                {label: '业务类型',prop: 'business_type_name'},
                {label: '账期类型',prop: 'account_period_type_name'},
                {label: '申请人',prop: 'staff_name'}
            ]

            out_excel_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.out_excel_data = s.result
                        this.$refs['out_excel'].outExcel()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_cooperative_enterprise_index()
        this.get_all_staff()
        this.get_all_bank_card()
        this.get_all_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CooperativeEnterpriseSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CooperativeEnterpriseEditComponent,
        CommonOutPutExcelComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>